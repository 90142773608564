import React from 'react';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import '../styles/carousel-fix.css';

export const Hero = ({ image, images, title }) => {
  /*
  const old = (
    <Img
      className="absolute w-full h-full rounded-b-3xl"
      imgStyle={{ objectFit: 'cover' }}
      fluid={image.childImageSharp.fluid}
      alt="sponsor"
    />
  );
  */

  return (
    <div className="relative flex items-end justify-center w-full h-108 rounded-b-3xl bg-s4tk-blue">
      <Carousel
        infiniteLoop
        showStatus={false}
        showIndicators={false}
        showArrows={false}
        autoPlay
        transitionTime={1000}
        interval={7000}
        showThumbs={false}
        height="34rem"
        className="absolute w-full h-full rounded-b-3xl"
      >
        {images ? (
          images.map((item, index) => (
            <PreviewCompatibleImage
              key={index}
              className="h-108 rounded-b-3xl"
              imgStyle={{ objectFit: 'contain' }}
              image={item.image}
              alt="banner-image"
            />
          ))
        ) : (
          <PreviewCompatibleImage
            className="h-108 rounded-b-3xl"
            imgStyle={{ objectFit: 'contain' }}
            image={image}
            alt="banner-image"
          />
        )}
      </Carousel>
      <div className="absolute top-0 left-0 z-10 w-full h-full bg-black opacity-20 rounded-b-3xl" />
      <p className="z-20 mb-4 text-4xl font-semibold tracking-tight text-center text-white lg:text-6xl font-baloo">
        {title}
      </p>
    </div>
  );
};

export default Hero;
