import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import W4TKContent from '../components/Events/W4TKContent';

export const W4TKPageTemplate = ({ hero, blurb, items }) => {
  return (
    <div className="w-full flex flex-col bg-s4tk-white">
      <W4TKContent hero={hero} blurb={blurb} items={items} />
    </div>
  );
};

const W4TKPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <W4TKPageTemplate
        hero={frontmatter.hero}
        blurb={frontmatter.blurb}
        items={frontmatter.items}
      />
    </Layout>
  );
};

export default W4TKPage;

export const w4tkPageQuery = graphql`
  query W4TKPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "w4tk-page" } }) {
      frontmatter {
        hero {
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          text
        }
        blurb
        items {
          title
          url
          icon {
            title
            prefix
          }
        }
      }
    }
  }
`;
